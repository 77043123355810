<template>
  <div v-if="canAccess('cobranzas_admin')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <div class="p-grid" :style="{padding: '15px'}">


      <Fieldset legend="Datos Ejecutivo de Cuenta" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-12" v-if="data.have_executive_account=='N'">
            <Message severity="error" :closable="false" key="msgError">Matrícula no tiene asignada un ejecutivo de cuenta</Message>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">


              <span class="p-float-label">
                  <AutoComplete  forceSelection v-on:item-select="assignment" id="idAssignment" :dropdown="true" :multiple="false" field="description"
                                v-model="idAssignment" :suggestions="personsAssignments"
                                @complete="getCatalog($event, 'subsidiaries/'+data.subsidiary_id+'/account-executives', 'description')">

                  </AutoComplete>

                    <label>Ejecutivo de Cuenta</label>
              </span>
            </div>
          </div>
          <div class="p-col-12 p-md-3" v-if="data.have_executive_account=='S'">
            <div class="p-field">
                <span class="p-float-label">
                      <input-text type="text" v-model="data.executive_account_date"
                                  class="p-inputtext-sm"/>
                    <label>Fecha de asignación de ejecutivo</label>
                </span>
            </div>
          </div>
          <div :class="data.have_executive_account=='S'?'p-col-12 p-md-6':'p-col-12 p-md-9'">
            <div class="p-field" style="width: 100%;text-align: right">
              <Button label="Regresar" class=" p-button-danger p-button-outlined" @click="$router.push('/raise-money/list')" />
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Datos Matrícula" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label >Nro. Matrícula</label>
              <input-text type="text" :value="data.sequential"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Fecha de Registro</label>
              <input-text type="text" :value="data.registration"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>País</label>
              <input-text type="text" :value="data.contract?.country?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Ciudad</label>
              <input-text type="text" :value="data.contract?.city?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Fecha Primer Pago</label>
              <input-text type="text" :value="data.contract?.date_first_payment"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Plan</label>
              <input-text type="text" :value="data.plan?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Valor Inscripción</label>
              <input-text type="text" :value="data.plan?.cost"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>Mes</label>
              <input-text type="text" :value="data.month?.month"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Semana</label>
              <input-text type="text" :value="data.week?.week"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Nota Remisión</label>
              <input-text type="text" :value="data.contract?.referral_note"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field   p-fluid">
              <label>Saldo</label>
              <input-text type="text" :value="data.balance"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Estado</label>
              <input-text type="text" :value="data.status"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>Origen de Matrícula</label>
              <input-text type="text" :value="data.registration_origin?.description"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Organización</label>
              <input-text type="text" :value="data.organization?.description"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Fecha Entrega Materiales</label>
              <input-text type="text" :value="data.contract?.delivery_date_materials"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field p-fluid">
              <label>Asesor</label>
              <input-text type="text" :value="data.employee?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label>Filial</label>
              <input-text type="text" :value="data.subsidiary?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>El titular es beneficiario?</label>
              <input-text type="text" :value="data.is_holder_student=='y'?'SI':'NO'"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field p-fluid">
              <label>Costo Total</label>
              <input-text type="text" :value="data.plan?.total_value"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>

        </div>
      </Fieldset>
      <Fieldset legend="Datos Personales" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Cédula</label>
              <input-text id="ced" type="text" :value="data.contract?.holder?.dni"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label for="mail">Email</label>
              <input-text id="mail" type="text" :value="data.contract?.holder?.email"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label for="name">Nombres</label>
              <input-text id="name" type="text" :value="data.contract?.holder?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label for="last_name">Apellidos</label>
              <input-text id="last_name" type="text" :value="data.contract?.holder?.last_name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field   p-fluid">
              <label for="phone">Teléfono</label>
              <input-text id="phone" type="text" :value="data.contract?.holder?.phone"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label for="mobile_phone">Número de celular</label>
              <input-text id="mobile_phone" type="text" :value="data.contract?.holder?.mobile_phone"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field p-fluid">
              <label for="civil_status">Estado civil</label>
              <input-text id="civil_status" type="text" :value="data.contract?.holder?.civil_status"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field   p-fluid">
              <label for="birth_date">Fecha de nacimiento</label>
              <input-text id="birth_date" type="text" :value="data.contract?.holder?.birth_date"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Datos Domicilio" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Pais</label>
              <input-text type="text" :value="data.contract?.holder?.country?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Ciudad</label>
              <input-text id="mail" type="text" :value="data.contract?.holder?.city?.name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Calle Principal</label>
              <input-text id="name" type="text" :value="data.contract?.holder?.main_street"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Calle Secundaria</label>
              <input-text type="text" :value="data.contract?.holder?.second_street"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field   p-fluid">
              <label >Nro. Casa</label>
              <input-text type="text" :value="data.contract?.holder?.house_number"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Código Postal</label>
              <input-text type="text" :value="data.contract?.holder?.postal_code"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field p-fluid">
              <label>Tipo de Vivienda</label>
              <input-text type="text" :value="data.contract?.holder?.my_type_housing"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
        </div>
      </Fieldset>
      <Fieldset legend="Datos Laborales" :toggleable="true" class="p-col-12">
        <div class="p-grid">
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Nombre de La Empresa</label>
              <input-text type="text" :value="data.contract?.holder?.company_name"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Teléfono - Extensión</label>
              <input-text type="text" :value="data.contract?.holder?.company_phone+' Ext: '+data.contract?.holder?.company_phone_extension"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Email de la empresa</label>
              <input-text type="text" :value="data.contract?.holder?.company_email"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Dirección de La Empresa</label>
              <input-text type="text" :value="data.contract?.holder?.company_address"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Tipo de empleado</label>
              <input-text type="text" :value="data.contract?.holder?.my_type_employee"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Fecha de Ingreso</label>
              <input-text type="text" :value="data.contract?.holder?.date_admission"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Cargo</label>
              <input-text type="text" :value="data.contract?.holder?.position"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Facebook</label>
              <input-text type="text" :value="data.contract?.holder?.facebook"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Skype</label>
              <input-text type="text" :value="data.contract?.holder?.skype"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
          </div>
          <div class="p-col-12 p-md-3">
            <div class="p-field">
              <label>Ingreso Mensual</label>
              <input-text type="text" :value="data.contract?.holder?.monthly_income"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>
            <div class="p-field">
              <label>Observación</label>
              <textarea rows="5" style="width: 100%" :value="data.contract?.holder?.observation"
                          class="p-inputtext-sm"
                          :disabled="true"/>
            </div>

          </div>
        </div>
      </Fieldset>


    </div>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">></ConfirmDialog>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>
<script>

import service from '../../service/raiseMoney.service'
import bouncer from "../../../../helpers/bouncer";
import Message from 'primevue/message';
import catalogService from "../../service/catalog.service";
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  mixins: [bouncer],
  name: "RaiseMoneyDetail",
  components: {
    Message,ConfirmDialog
  },
  data() {
    return {
      changeEdit:false,
      personsAssignments:[],
      dateAssignment:'',
      idAssignment:'',
      loading: false,
      data: {},
      submitted: false,
      employees: [],
      months: [],
      weeks: [],
      subsidiaries: [],
      companies: [],
      organizations: [],
      payments: [],
      plans: [],
      origins: [],
      startDate: null,
      endDate: null,
    }
  },
  mounted() {
    if (this.canAccess('cobranzas_admin')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Matricula para gestión de Cobranza', url: "javascript:void(0);"},
      ]);
      this.getData();
    }
  },
  methods: {
    async getData() {
     await service.getItem(this.$route.params.id, this.$route.params.enrollment,{}).then(x => {
       this.data = x.data.result;
        if( this.data.have_executive_account=='S'){
          this.idAssignment={
            'id':this.data.executive_account,'description':this.data.executive_account_name
          }
        }
        console.log(this.idAssignment);
      }).finally(() => this.loading = false)
    },
    getCatalog(event,route) {
      catalogService.getCatalog(event, route, 'description').then(x => {
          this.personsAssignments = x;
      });
    },
    assignment(){
      service.assigment(this.$route.params.enrollment,{
        'employee':this.idAssignment.id
      }).then(x => {
        this.data.have_executive_account='S';
        this.data.executive_account_date=x.data.updated_at
        this.$toast.add({severity:'success', summary: 'Registro guardado', detail:'Se guardó correctamente', life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)
    }
  }
}
</script>
<style lang="scss" scoped>
.p-progressbar {
  height: 1px;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  width: 100%;
}

.p-fieldset {
  margin-top: 15px;
}
.p-field>label{
    font-size: 13px;
}
.p-field {
  padding-top: 0px;
  margin-bottom: 6px;
}

.p-field-checkbox {
  margin-top: 30px;
}

.p-toolbar {
  margin-top: 10px;
  background-color: white;
}

.p-toolbar-group-right {
  width: 100%;
}

.p-calendar {
  height: 30px !important;
}

.p-autocomplete {
  height: 30px !important;
}

</style>
